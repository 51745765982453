import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ServicesFive = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "wwd5.jpg" }) {
        childImageSharp {
          fixed(quality: 80, width: 720) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Img
      style={{ height: "100%" }}
      fluid={data.placeholderImage.childImageSharp.fixed}
    />
  )
}

export default ServicesFive

import React from "react"

// BOOTSTRAP & LAYOUT/SEO
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Cta from "../components/cta"
// IMAGES
import ServicesOne from "../components/images/servicesImgOne"
import ServicesTwo from "../components/images/servicesImgTwo"
import ServicesThree from "../components/images/servicesImgThree"
import ServicesFour from "../components/images/servicesImgFour"
import ServicesFive from "../components/images/servicesImgFive"
import ServicesSix from "../components/images/servicesImgSix"

import CheckMark from "../images/check.svg"
import WhiteCheckMark from "../images/white-check.svg"
import BgPattern from "../components/images/bgPattern"

// CSS
import servicesStyles from "../components/services.module.css"

const Services = () => (
  <Layout>
    <SEO title="Services" />
    <BgPattern>
      <Container>
        <Row className={`${servicesStyles.firstService} ${servicesStyles.service}`} id="sales">
          <Col xl={5} className={`order-1 ${servicesStyles.image}`}>
            <ServicesOne />
          </Col>
          <Col xl={7} className={`order-2 ${servicesStyles.description}`} >
            <h2 className={servicesStyles.underlineLeft}>Sales and Business Relationship Management</h2>
            <p>We manage and coordinate all day-to-day administrative, functional, operational, and accounting activities between your salesforce and your customers.</p>
            <Row className={servicesStyles.pointsList} noGutters>
              <Col xs="auto" className={servicesStyles.pointLeft}>
                <ul>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Sales showroom
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Modular planning
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    POS analysis
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Product testing support
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Broker network maintenance
                  </li>

                </ul>
              </Col>
              <Col xs="auto" className={servicesStyles.pointLeft}>
                <ul>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Key account relationship management
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Daily buyer contact and reporting
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Customer and Channel Pricing
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Sales force communication
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Value Ladder and Assortment Planning
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BgPattern>
    <div style={{ backgroundColor: "#f1f1f1" }}>
      <Container>
        <Row className={servicesStyles.service} id="demand">
          <Col
            xl={7}
            className={`order-2 order-xl-1 ${servicesStyles.description}`}
          >
            <h2 className={servicesStyles.underlineRight}>Demand Planning & Supply Chain Analytics</h2>
            <p>We build integrated predictive tools in concert with both customers and suppliers to ensure effective and efficient supply in every segment of the supply chain from factory raw materials inventory to retail ready finished product inventory on the shelf or ready to ship.</p>
            <Row className={servicesStyles.pointsList} noGutters>
              <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>
                  <li>
                    Shelf Pack Optimization
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Distribution Network Planning
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Distribution Network Optimization
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                </ul>
              </Col>
              <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>
                  <li>
                    Vendor & Warehouse Case Pack Optimization
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Channel Supply Chain Optimization
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Customer Supply Chain Optimization
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xl={5} className={`order-1 order-xl-2 ${servicesStyles.image}`}>
            <ServicesTwo />
          </Col>
        </Row>
      </Container>
    </div>
    <BgPattern>
      <Container>
        <Row className={servicesStyles.service} id="warehousing">
          <Col xl={5} className={`order-1 ${servicesStyles.image}`}>
            <ServicesThree />
          </Col>
          <Col xl={7} className={`order-2 ${servicesStyles.description}`}>
            <h2 className={servicesStyles.underlineLeft}>Warehousing and Distribution Management</h2>
            <p>We manage your inventory globally both in our 50,000 sf NW Arkansas warehouse and/or in other warehouses throughout North America.  Our ability to manage inventory globally enables SAI to design and implement innovative distribution network strategies that help your customers lower their net landed cost of goods.</p>
            <Row className={servicesStyles.pointsList}>
              <Col xs="auto" className={servicesStyles.pointLeft}>
                <ul>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Lot Tracking
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Container Tracking
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Online Drop Ship
                  </li>
                </ul>
              </Col>
              <Col xs="auto" className={servicesStyles.pointLeft}>
                <ul>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Project & program management
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Certified Safe Quality Food Facility
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    50,000 SF Warehouse located in Rogers, AR
                  </li>
                </ul>
              </Col>
              <Col xs={12} className={servicesStyles.pointLeft}>
                <ul>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Balanced inventory flows and inventory across distributed warehouse networks
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Limited Build-To-Order capabilities from engineered component part inventories
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Repair and Replacement Part Services
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BgPattern>
    <div style={{ backgroundColor: "#f1f1f1" }}>
      <Container>
        <Row className={servicesStyles.service} id="outsourcing">
          <Col
            xl={7}
            className={`order-2 order-xl-1 ${servicesStyles.description}`}
          >
            <h2 className={servicesStyles.underlineRight}>
              Business Process Outsourcing
            </h2>
            <p>We manage and coordinate all day-to-day technological, functional, operational, and accounting
              activities so you can focus on higher-value processes and activities such as business and supplier
              development.</p>
            <Row className={servicesStyles.pointsList}>
              <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>
                  <li>
                    Journal entry adjustment
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Financial controller functions
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Monthly closing & reporting
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Cash flow & cash anticipation modeling
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                </ul>
              </Col>
              <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>
                  <li>
                    Integrated EDI
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Accounting
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Inventory Control
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Year end reporting
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xl={5} className={`order-1 order-xl-2 ${servicesStyles.image}`}>
            <ServicesFour />
          </Col>
        </Row>
      </Container>
    </div>
    <BgPattern>
      <Container>
        <Row className={servicesStyles.service} id="marketing">
          <Col xl={5} className={`order-1 ${servicesStyles.image}`}>
            <ServicesFive />
          </Col>
          <Col xl={7} className={`order-2 ${servicesStyles.description}`}>
            <h2 className={servicesStyles.underlineLeft}>
              Marketing and Product Development
            </h2>
            <p>Our expert project management skills allow SAI to effectively & efficiently manage our network of strategic partners and other stakeholders to deliver rapid business results through integrated parallel processes of product design, manufacturing, and market deployment.  This includes all packaging, instruction sheets, photography, and on-line product marketing.   Our goal is to go from concept to delivery as quickly as possible so that you can achieve a speedy and positive business result.</p>
            <Row className={servicesStyles.pointsList}>
              <Col className={servicesStyles.pointLeft}>
                <ul>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Product development
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Trend analysis
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Digital media
                  </li>
                  <li>
                    <img src={CheckMark} alt="Checkmark" width="20" />
                    Professional photography & video production
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BgPattern>
    <div style={{ backgroundColor: "#f1f1f1" }}>
      <Container>
        <Row className={servicesStyles.service} id="strategic">
          <Col
            xl={7}
            className={`order-2 order-xl-1 ${servicesStyles.description}`}
          >
            <h2 className={servicesStyles.underlineRight}>
              Strategic Planning
            </h2>
            <p>Assessing honestly and realistically the current capabilities defined within the structure of the Business Diamond</p>
            <p>Articulating a clear and specific desired future state within the structure of the Business Diamond</p>
            <p>Developing an integrated strategic plan addressing every area of the Business Diamond</p>
            <Row className={servicesStyles.pointsList}>
              {/* <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>

                </ul>
              </Col> */}
              <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>
                  <li>
                    Defining roles, responsibility, and accountability
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <span>Implementation of “Mission Orientation”<br /> for subordinate leaders</span>
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                </ul>
              </Col>
              <Col xs="auto" className={servicesStyles.pointRight}>
                <ul>
                  <li>
                    Increase Productivity
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Leadership Assessment
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Long-Term Goals
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    SWOT Analysis
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                  <li>
                    Action Plans
                    <img src={WhiteCheckMark} alt="Checkmark" width="20" />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xl={5} className={`order-1 order-xl-2 ${servicesStyles.image}`}>
            <ServicesSix />
          </Col>
        </Row>
      </Container>
    </div>
    <Cta text="Strategic Alliances is a TRUSTED ADVISOR and PARTNER who will help you achieve your business goals" serv={true} />
  </Layout>
)

export default Services
